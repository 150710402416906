/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "DemoLight";
  src: url("./assets/fonts/MyFontsWebfontsKit/MyFontsWebfontsKit/webFonts/Demo-Light/font.woff") format("otf");
  font-weight: 100 - 300;
}
@font-face {
  font-family: "Demo";
  src: url("./assets/fonts/MyFontsWebfontsKit/MyFontsWebfontsKit/webFonts/Demo-Regular/font.woff") format("otf");
  font-weight: 400;
}
@font-face {
  font-family: "Demo-medium";
  src: url("./assets/fonts/MyFontsWebfontsKit/MyFontsWebfontsKit/webFonts/Demo-Medium/font.woff");
  font-weight: 500, 600, 700, 800, 900;
}

@font-face {
  font-family: "DemoFont";
  src: url("./assets/fonts/DemoLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HeeboFont";
  src: url("./assets/fonts/Heebo-Light.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//   font-family: myFirstFont;
//   src: url(../MyFontsWebfontsKit/webFonts/Demo-Regular/font.woff);
// }
//general colors
$mainColor: linear-gradient(
  180deg,
  #002947 20.44%,
  #004366 64.03%,
  #024469 100%
);
$secondaryColor: #00b5d4;

:root {
  --white: #ffffff;
  --mainBlue: #024266;
  --mainBlueDark: #002947;
  --red: #ff6f74;
  --darkRed: #ff636f;
  --primaryGradient: linear-gradient(
    135deg,
    #5ee6e5 0%,
    #00bad8 53.4%,
    #00b5d4 100%
  );
  --redMainColor: #ff6f74;
  --primaryColor: #00dded;
  --mainFontColor: #b1c6d4;
  --secondaryFontColor: #004369;
}
html,
body,
* {
  font-family: "DemoFont" ;
}
.hebrow{
  font-family: "HeeboFont";
}
pre {
  overflow-wrap: break-word !important;
}
dfn{
  font-style: normal;
  font-weight: 400;
  font-family: inherit !important;
}
.btn-n {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  // padding: 16px;
  // border: none;
  // border-radius: 100000px;
  cursor: pointer;

  transition: 0.25s ease-out;
}
.btn-n:active,
.btn-n:hover {
  transform: scale(1.2);
  background-color: rgb(240, 240, 240 , 0.2);
}

.link{
  color: #00b5d4;
}
.ref{
  font-size: 0.8rem;
}
.ideation{
  font-size: 1.5rem;
  font-weight: bold;
}
app-side-bar {
  position: fixed;
  width: 321px;
  height: 100vh;
  background: white;
  position: sticky;
}
app-side-bar.dark-mode {
  background: #002136 !important;
}
// darck mode --->
.dark-mode {
  background: #002136 !important;
  color: #fff !important; /* Dark mode text color */
  .app-side-bar {
    background: #002136 !important;
  }
  .logo-rap {
    p {
      background: none !important;
      background-clip: unset !important;
      -webkit-background-clip: unset !important;
      -webkit-text-fill-color: unset !important;
      color: white !important;
    }
  }
  .a-help{
    color: white !important;
  }
  .textarea-input {
    textarea::placeholder {
      color: white !important;
    }
  }
  .model-help.ng-star-inserted,
  .model-help {
    background: #002136 !important;
  }
  .mat-menu-panel {
    background: #01456b !important;
    color: white !important;
  }
  .p-his,
  .t-mob {
    color: white !important;
  }
  .main-back {
    background: rgba(255, 255, 255, 0) !important;
    background: linear-gradient(
      180deg,
      #0b3b5f 20.44%,
      #024669 64.03%,
      #01456b 100%
    ) !important;
  }
  .chat-window .info-box {
    background: #18283d !important;
    min-height: min-content;
  }
  .link{
    color: #00b5d4;
  }
  h1,
  .h2-example,
  .chat-window .info-p,
  .setting-rap .name,
  .text-gray-800,
  .fmessage-p {
    color: white !important;
  }
  .textarea-input {
    border: 1px solid #fff !important;
    background: #18283d !important;
  }
  .textarea-input textarea {
    color: white !important;
  }
  .message-ai .app-container {
    border: 1px solid #5ee6e5 !important;
    background: #002136 !important;
  }
  .setting-rap .model-setting {
    background: #01456b !important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.13) !important;
  }

  .p.p-his {
    color: white;
  }
  .text-ex {
    background: rgba(255, 255, 255, 0.2) !important;
    color: white !important;
  }

  button.help-btn.btn-rm {
    border: 1px solid #fff !important;
    background: #002136 !important;
  }
  .setting-rap {
    // border-top: 1px solid #fff !important;
    // background: linear-gradient(
    //   180deg,
    //   #002947 20.44%,
    //   #004366 64.03%,
    //   #024469 100%
    // ) !important;
    background: #002136 !important;
  }
  .stop-generating {
    background-color: #18283d !important;
    color: white;
  }
}
.font-InterThin {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  line-height: 32px;
}
.b-h1 {
  font-size: 40px;
}
.btn-rm {
  all: unset;
}

.btn-rm:focus {
  outline: revert;
}

code {
  font-size: 0.875em;
  color: white !important;
  word-wrap: break-word;
  width: 100%;
  display: block !important;
  background: #000 !important;
  padding: 11px;
  border-radius: 8px;
}
pre {
  margin-bottom: 0;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--code-token-comment);
}

.token.punctuation {
  color: var(--code-token-punctuation);
}

.token.attr-name,
.token.builtin,
.token.inserted,
.token.selector,
.token.property,
.token.class-name,
.token.function {
  color: var(--code-token-attribute-name);
}

.token.atrule,
.token.attr-value {
  color: var(--code-token-attribute-value);
}

.token.keyword {
  color: var(--code-token-tag);
}

.token.tag,
.token.char,
.token.string,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: var(--code-token-attribute-value);
}

.token.selector,
.token.builtin,
.token.template-string > .token.string,
.token.inserted {
  color: var(--code-token-selector);
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
.img-his,
.t-mob,
.p-his {
  display: none;
}
.message-ai{
  font-family: "Demo-light";
}
@media only screen and (max-width: 1200px) {
  .open-sidbar {
    display: flex !important;
    align-items: center;
    justify-content: center;
    right: 32px;
    z-index: 2;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #efefef;
    margin-left: auto;
  }
  .logo-rap {
    gap: 0 !important;
  }
  .textarea-input{
    margin-bottom: 19px;
  }
  mat-icon.mat-icon.notranslate.send.material-icons{
    color: #00B5D4 !important;
  }
  .stop-generating{
    top: -38px !important;
    right: 14px !important;
  }
  body .chat-window {
    .chatMessage.group {
      padding-left: 0 !important;
    }
  }
  .title-mes{
    border-top: 5px solid  #D9D9D9 !important;
    height: 38px !important;
    span{
      color: #85878B;
      text-align: center;
      // font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .mat-menu-content:not(:empty) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  button.mat-menu-item {
    width: 271px !important;
  }
  .dark-mode {
    .mat-menu-panel {
      background: #01456b !important;
      color: white !important;
      button {
        color: white !important;
      }
    }
    .msg-container.ng-star-inserted {
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        #0b3b5f 20.44%,
        #024669 64.03%,
        #01456b 100%
      ) !important;
    }
    .title-mes{
      span{
        color: #FFF;
      }
    }
  }
  .message-ai .app-container {
    padding-right: 0 !important;
    padding-bottom: 32px !important;
  }
  .msg-container__side {
    gap: 14px;
    margin-top: 14px;
  }
  .chat-window {
    padding-bottom: 40px !important;
  }
  .rap-info {
    flex-direction: column;
  }
  .img-his {
    display: inline-block;
    position: absolute;
    right: 19px;
    top: 16px;
  }
  .msg-container {
    padding: 17px;
    button.ng-star-inserted.msg-container_side-chat_dark,
    button.ng-star-inserted.msg-container_side-chat_active_dark,
    button.msg-container_side-chat_active.ng-star-inserted,
    .msg-container_side-chat.ng-star-inserted,
    .button.msg-container_side-chat_active.ng-star-inserted {
      display: flex;
      flex-direction: column;
      display: flex;
      height: 65px;
      padding: 10px 13px 0px 13px;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      span {
        right: 0 !important;
      }
    }
  }

  .msg-container__side {
    width: 100% !important;
    button {
      width: 100% !important;
      display: flex !important;
      min-height: 51px;
      padding: 10px 13px 0px 13px !important;
      flex-direction: row;
      align-items: flex-start;
      gap: 2px;
    }
  }
  .t-mob {
    color: #002136;
    text-align: start;
    // font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
  }
  .p-his {
    color: #000;
    text-align: center;
    // font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 137%; /* 21.92px */
    display: block;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 16px;
  }
  app-side-bar {
    display: none;
    width: 0;
  }
  .stop-generating {
    position: absolute;
    right: 7px;
    margin-bottom: 0 !important;
  }
  main {
    padding-left: 0 !important;
  }
  .app-side-bar-show {
    .body {
      display: block !important;
      width: 100vw;
      height: 100vh;
      position: fixed;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 345;
      z-index: 2343454;
      .msg-container {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block !important;
        background: white;
        width: 100vw;
        z-index: 360;
        margin: 0;
        transition: all 0.3s ease;
        padding-bottom: 45px !important;
        svg {
          display: none !important;
        }
      }
    }
  }

  .chat-window {
    padding-top: 140px;
    padding-bottom: 65px !important;
  }
  .textarea-input {
    bottom: 0 !important;
  }
  .user-input {
    padding: 0 !important;
    form {
      // padding: 19px !important;
      margin: 0 !important;
    }
  }

  app-side-bar {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px,
    //   rgba(0, 0, 0, 0.14) 0px 16px 24px 2px,
    //   rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: fit-content;
    flex: 1 0 auto;
    z-index: 2;
    position: fixed;
    top: 0px;
    outline: 0px;
    left: 0px;
    width: 100%;
    button.new_chat_container__btn_new_chat_active,
    button.new_chat_container__btn_new_chat_dark,
    button.new_chat_container__btn_new_chat {
      display: flex;
      width: 286px !important;
      padding: 10px 84px 10px 13px !important;
      align-items: center !important;
      gap: 15px;
    }
    .setting-rap {
      display: none !important;
      width: 0;
    }
    .msg-container {
      display: none !important;
    }

    .sidbar-rap {
      margin: 18px 25px 20px 10px !important;
    }
    .logo-rap {
      margin-bottom: 16px;
      gap: 4px;
      margin: 8px 0px 0px 0px !important;
      p {
        margin-bottom: 0 !important;
        font-size: 13.636px;
        line-height: 13.636px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      img {
        height: 20px;
        width: 52px;
      }
    }
  }
  .cdk-overlay-connected-position-bounding-box {
    top: 73px !important;
  }
  .mat-menu-panel {
    max-width: 271px !important;
    overflow: hidden !important;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 48px);
    border-radius: 4px;
    outline: 0;
    min-height: 64px;
    position: relative;
    display: flex;
    border-radius: 8px !important;
    width: 271px !important;
    gap: 10px;

    .mat-menu-item {
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: none;
      border: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 48px;
      height: 48px;
      padding: 0 16px;
      text-align: left;
      text-decoration: none;
      max-width: 100%;
      position: relative;
      max-width: 270px;
    }
    .mat-menu-content {
      button {
        // font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 1px solid #d9d9d9;
      }
      button:last-child {
        border-bottom: none;
      }
    }
    button.mat-menu-item {
      max-width: 270px !important;
    }
    .button.mat-focus-indicator {
      max-width: 270px !important;
    }
    .img-text {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
    }
    .text-btn {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      width: 90%;
    }
  }
  .main-warp {
    padding: 0 !important;
  }
  .user-input {
    background-image: none !important;
  }
  .chat-window {
    max-height: 95vh !important;
  }
  .sc-help {
    display: none;
  }
  .btn-copy-share {
    top: unset !important;
    bottom: 10px !important;
  }
  .message-ai .app-container {
    // margin: 1rem !important;
  }
  .chatMessage {
    padding-left: 16px;
  }
  .message-ai {
    padding-left: 0;
  }
  app-header {
    display: block;
  }
  .message-ai {
    max-width: 100vw !important;
  }
}
@media only screen and (min-width: 1200px) {
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}
}

.msg-container::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 7px;
}

.msg-container {
  overflow-y: scroll !important;
  transition: background-color 0.5s ease;
}

pre {
  margin-bottom: 0 !important;
}
.message-ai,
button {
  transition: all 0.2s ease;
}
.message-ai {
  * {
    transition: all 0.2s ease;
  }
}

main {
  padding-left: 39px;
}

p.comment {
  font-size: smaller;
}

.btn-neutral{
  white-space: normal;
  color: black;
  text-align: left;
  padding: 0.75rem 1rem !important;
  border: 1px solid;
  border-radius: 0.75rem !important;
  width: 100%;
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--tw-bg-opacity));
  border-color: rgba(0,0,0,.1);
  border-width: 1px;
  font-size: .875rem;
  line-height: 1.25rem;
  align-items: center;
}

.truncate{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &.title{
    font-weight: 500;
  }
}

.items-center {
  align-items: center;
}
.chatMessage.group {
  padding-left: 88px ;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-normal {
  font-weight: 400;
}

button.swal2-confirm.swal2-styled{
  background-color: #5ee6e5;

}
a {       color: rgb(32 170 199) !important; }

.refBig{
  font-size: larger;
  font-weight: bold;
}

.swal-custom-font {
  font-family: "HeeboFont";
  font-size: 20px; /* אפשר להגדיר גודל פונט אם צריך */
  direction: rtl; /* במידה והטקסט בעברית */
}

.ref {
  position: relative;
  // display: inline-block;
}

.tooltipQuote {
  visibility: hidden;
  position: absolute;
  // bottom: 100%;
  // left: 50%;
  // transform: translateX(-50%);
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
  width: 450px;
}

.ref:hover .tooltipQuote {
  visibility: visible;
}